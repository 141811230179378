.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-wrapper {
  position: relative;
  min-height: 80vh;
  width: 100%;
}

.flexContainer {
  /*display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;*/
}

.flexContainer > .leftMenuContainer {
  width: auto;
  background-color: rgb(52, 58, 64);
}

@media (max-width: 600px) {

}

.flexContainer > .rightContentContainer {
  -webkit-flex: auto;
  flex: auto;
}

.workAreaWrapper {
  position: relative;
  min-height: 65vh;
  width: 50%;
  color: black;
  margin: 0 auto;
}

@media (max-width: 800px) {
  .workAreaWrapper {
    width: 98%;
    min-height: 100vh;
  }
}

.workAreaWrapperLarge {
  position: relative;
  min-height: 100vh;
  width: 78%;
  color: black;
  margin: 0 auto;
}

.workAreaWrapperExtraLarge {
  position: relative;
  min-height: 100vh;
  width: 100%;
  color: black;
  margin: 0 auto;
}

@media (max-width: 800px) {
  .workAreaWrapperLarge {
    width: 98%;
    min-height: 100vh;
  }
}


.centralWrapper {
  width: 80%;
  margin: 0 auto;
  padding-top: 2%;
}

@media (max-width: 800px) {
  .centralWrapper {
    width: 96%;
  }
}

#light {
  background-color: #eee;
}

#dark {
  background-color: rgb(11, 22, 33);
  /*background: linear-gradient(328deg, rgba(186,44,47,1) 0%, rgba(47,39,119,1) 50%, rgba(10,20,30,1) 100%);*/
  /*background: linear-gradient(328deg, rgb(70, 135, 189) 0%, rgb(39, 32, 107) 50%, rgba(10,20,30,1) 100%);*/
  /*background-color: rgb(8, 11, 23) !important;*/
  background-color: rgb(8, 18, 38) !important;
  background-color: rgb(6, 14, 22) !important;
}

.accordion-button {
  background-color: rgba(11, 22, 33, 0.01) !important;
  color: gray !important;;
  border: none !important;;
}

.accordion-button:focus {
  box-shadow: none!important;
  outline: none !important;
}

.accordion-button:not(.collapsed) {
  color: gray;
}

.btn-close {
  background:  transparent url('https://picsum.photos/25/25');
}

.leaflet-container {
  min-width: 300px; 
  min-height: 200px;
  width: auto;
  height: auto;
}

.leaflet-control-attribution.leaflet-control {
  display: none;
}

.leaflet-control { z-index: 0 !important}
.leaflet-pane { z-index: 0 !important}
.leaflet-top, .leaflet-bottom {z-index: 0 !important}

#light h2 {
  color: rgba(0, 0, 0, 0.75) !important;
}

#dark h2 {
  color: rgba(240, 240, 240, 0.8) !important;
}

#light h3 {
  color: gray !important;
}

#dark h3 {
  color: rgba(240, 240, 240, 0.8) !important;
}

#light h4 {
  color: rgba(0, 0, 0,) !important;
}

#dark h4 {
  color: rgba(240, 240, 240, 0.8) !important;
}

#light h5 {
  color: rgba(0, 0, 0, 0.75) !important;
}

#dark h5 {
  color: rgba(240, 240, 240, 0.8) !important;
}

#light h6 {
  color: rgba(0, 0, 0, 0.75) !important;
}

#dark h6 {
  color: rgba(240, 240, 240, 0.8) !important;
}

#light p {
  color: rgba(0, 0, 0, 0.75) !important;
}

#dark p {
  color: rgba(240, 240, 240, 0.8) !important;
}

#light a {
  color: rgba(0, 0, 0, 0.75) !important;
}

#dark a {
  color: rgba(240, 240, 240, 0.8) !important;
}