

.wrapper {
    position: absolute;
    top: 30px;
    right: 10%;
    width: 500px;
    height: 600px;
    
    color: rgba (240, 240, 240, 0.9);
    padding: 20px;
    border-radius: 4px;
}

#dark .wrapper {
    background-color: rgb(6, 14, 22);
    color: rgb(128, 128, 128); 
}

#light .wrapper {
    background-color: rgb(245, 245, 245);
    color: rgb(128, 128, 128); 
}

@media (max-width: 600px) {
    .wrapper {
        position: absolute;
        top: 7vh;
        right: 0;
        width: 100%;
        height: 80vh;
    }
}

#light h5 {
    color: rgb(128, 128, 128); 
}

.wrapper:hover {
    
}

.titleWrapper {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 10%;
}

.closeIcon {
    position: absolute;
    top: 0;
    right: 0;
}

.bodyWrapper {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 90%;
    overflow-y: scroll;
}