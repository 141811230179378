.footerBottomCustom {
    color: white;
    background-color: rgb(28, 35, 49);
    width: 100%;
    min-height: 300px;
}

a {
    color:rgba(240, 240, 240, 0.65);
}

a:hover {
    color: rgb(265, 165, 0);
}

#dark {
    background-color: rgb(10, 20, 30);
    /*border-top: 1px solid rgb(157, 209, 202);*/
    color: rgba(240, 240, 240, 0.75);
}

#light {
    background-color: rgb(111, 172, 237);
    /*background-color: rgb(0, 122, 204);*/
    border-top: 1px solid rgba(180, 180, 180, 0.8);
    color: white;
}

#light h5 {
    color: rgba(0, 0, 0, 0.75) !important;
}

#light a {
    color: rgba(0, 0, 0, 0.75) !important;
}

#light a:hover {
    color: rgb(265, 165, 0) !important;
}

#light p {
    color: rgba(0, 0, 0, 0.75)  !important;
}

.green {
    border: 1px solid greenyellow !important;
    color: greenyellow !important;
}

.orange {
    border: 1px solid orange !important;
    color: orange !important;
}

.red {
    border: 1px solid rgb(212, 78, 37) !important;
    color: rgb(212, 78, 37) !important;
}

.blue {
    border: 1px solid rgb(93, 182, 255) !important;
    color: rgb(93, 182, 255) !important;
}

.pink {
    border: 1px solid rgb(214, 25, 231) !important;
    color: rgb(214, 25, 231) !important;
}

.yellow {
    border: 1px solid rgb(220, 255, 93) !important;
    color: rgb(220, 255, 93) !important;
}

.black {
    border-left: 4px solid rgb(2, 9, 20);
    color: rgb(2, 9, 20);
}

.qrCodeWrapper {
    width: 100%;
    height: auto;
    
}

.qrCodeImg {
    width: auto;
    height: auto;
    max-width: 128px;
    max-width: 128px;
}