.formWrapper {
    
}

.headerWrapper {
    width: 100%;
    height: 20%;
}

.bodyWrapper {
    width: 100%;
    height: 60%;
}

.footerWrapper {
    width: 100%;
    height: 20%;
    text-align: center;
}

.chooseButtons {
    margin: 4px;
    font-size: 1em;
    border: 1px solid rgba(0,0,0,.125);
    background-color: white;
    color: rgb(121,121,121);
}

.serviceLinkWrapper:hover {
    background-color: white;
    color: rgb(121,121,121);
    display: inline-block;
}

h2 {
    display: inline-block;
}

#dark {
   
}