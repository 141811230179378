.container {
    /*scrollbar-color: rebeccapurple green !important;*/
    scrollbar-width: thin !important;
}

.wrapper {
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
    z-index: 20;
    
    /*border: 1px solid aquamarine;*/
    background-color: rgba(9, 9, 22, 0.9);
}

.expanded {
    position: fixed;
}

.parkingAreawrapper {
    position: relative;
    width: 100%;
    height: 650px;
    margin-top: 20px;
    margin-bottom: 20px;
    overflow: scroll;
}

.crudButtons {
    display: inline-block;
    margin: 10px;
}

.expandButtons {
    float: right;
    margin: 10px;
}

#lightParkingArea {
}