.wrapper {
    width: 100%;
    /*border: 1px solid rgba(230, 50, 50, 0.5);*/
    display: table;
}

.tableCell {
    display: table-cell;
    text-align: center;
}

#dark {
    /*background-color: rgba(11, 22, 33, 0.7);*/
    border: 1px solid rgba(230, 50, 50, 0.5);
    box-shadow: none;
}