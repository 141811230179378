.taskInformation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 15px;
    min-height: 106px;
    border-radius: 5px;
    max-width: 311px;
    background: rgba(44, 44, 44, 0.5);
    margin-top: 15px;

    .secondary-details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-size: 12px;
        font-weight: 400px;
        color: #7d7d7d;
    }
    /* .priority{ */
    /* margin-right: 12px; */
    /* align-self: center;
        svg{
        width: 12px !important;
        height: 12px !important;
        margin-right: 12px; */
    /* margin-top: 2px; */
    /* } */
    /* } */
}