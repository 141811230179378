.headerWrapper {
    width: 200;
    /*min-width: 600px;*/
    margin: 0 auto;
    padding: 4%;
}

.slotWrapper {
    display: inline-block;
    margin: 0 auto;
}

.slotListWrapper {
    margin: 0 auto;
}