.wrapper {
    position: relative;
    width: 90%;
    min-height: 120px;
    padding: 10px;
    z-index: 22;
    margin: 2% auto;
    border-radius: 4px;
}

#dark .wrapper {
    background-color: rgb(12, 22, 34);
    
}

#light .wrapper {
    background-color: rgb(255, 255, 255);
    color: rgb(128, 128, 128);
}

.wrapper:hover {
    transform: scale(1.02);
    transition-duration: 0.5s;
}

.new {
    border: 1px solid rgb(0, 195, 255);
}





