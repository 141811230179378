.aboutWrapper {
    width: 80%;
    margin: 0 auto;
    padding: 5%;
}

.imgWrapper {
    max-width: 40%;
    float: left;
    padding-right: 20px;
}

.txtWrapper {
    color: gray
}

.teamWrapper {
    text-align: center;
}