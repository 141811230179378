.headerWrapper {
    width: 200;
    /*min-width: 600px;*/
    margin: 0 auto;
    padding: 4%;
}

.slotWrapper {
    display: inline-block;
    margin: 0 auto;
}

.slotListWrapper {
    margin: 0 auto;
}

.formWrapper {
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    padding: 10px;
    box-shadow: 10px 10px 10px rgb(0 0 0 / 50%);
    transition: all 0.5s ease;
    color: rgb(121,121,121);
    background-color: white;

    height: auto;
}

.headerWrapper {
    width: 100%;
    height: 20%;
}

.bodyWrapper {
    width: 100%;
    height: 60%;
}

.footerWrapper {
    width: 100%;
    height: 20%;
    text-align: center;
}

.itemWrapper {
    text-align: center;
}

.chooseButtons {
    margin: 4px;
    font-size: 1em;
    border: 1px solid rgba(0,0,0,.125);
    background-color: white;
    color: rgb(121,121,121);
}

.serviceLinkWrapper:hover {
    background-color: white;
    color: rgb(121,121,121);
    display: inline-block;
}

.firmItemWrapper {
    display: inline-block;
}

#dark {
    background-color: rgba(11, 22, 33, 0.9);
    /*border: 1px solid rgba(230, 50, 50, 0.8);*/
    box-shadow: none;
}