.errorWrapper {
    position: fixed;
    bottom: 4px;
    left: 4px;
    width: 400px;
    height: 200px;
    background-color: rgb(41, 0, 0);
    color: rgb(255, 128, 128);
    border: 1px solid rgba(230, 50, 50, 0.8);
    padding: 4px;
}

.visibleModal {
    display: block;
}

.invisibleModal {
    display: none;
}

.close {
    color: #fff; 
    opacity: 1;
    font-size: 2em;
}