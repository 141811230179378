.wrapper {
    width: 300px;
    min-height: 360px;
    font-size: large;
    color: rgb(121,121,121);   
    margin: 4px;
    transition: all 0.5s ease;
    border-radius: 4px;
    padding: 8px;
    display: inline-block;
}

.imageWrapper {
    text-align: center;
    width: 100%;
    text-align: center;
}