.wrapper {
    position: relative;
    width: 220px;
    height: 380px;
    font-size: large;
    color: rgb(121,121,121);   
    margin: 8px;
    transition: all 0.5s ease;
    border-radius: 4px;
    padding: 8px;
    display: inline-block;    
}

.wrapper:hover {
    transform: scale(1.02);
    cursor: pointer;
}

.imageWrapper {
    width: 200px;
    height: 100px;
    display: inline-block;
}

#light {
    background-color: white;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    box-shadow: 10px 10px 10px rgb(0 0 0 / 50%);
}

#dark {
    background-color: rgb(11, 22, 33);
    /*color: rgba(230, 50, 50, 0.6);*/
    background: linear-gradient(45deg, rgba(123,64,232,1) 0%, rgba(207,70,131,1) 35%, rgba(254,115,74,1) 100%);
    color: white;
    
}

#dark:hover {
    /*border: 1px solid rgba(230, 50, 50, 0.8);*/
    /*box-shadow: 10px 10px 10px rgba(230, 50, 50, 0.8);*/
    
}

.descriptionWrapper {
    display: inline-block;
}

.header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 20%;
    font-size: 1.6em;
    text-align: center;
}

.body {
    position: absolute;
    top: 20%;
    left: 0;
    width: 100%;
    height: 60%;
    padding-left: 15px;
}

.footer {
    position: absolute;
    top: 80%;
    left: 0;
    width: 100%;
    height: 20%;
    text-align: center;
}
