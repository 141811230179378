.headerWrapper {
    width: 80%;
    /*min-width: 600px;*/
    margin: 0 auto;
    padding: 4%;
}

.servicesListWrapper {
    text-align: center;
}

h3 {
    color:rgba(240, 240, 240, 0.75);
}