.authForm {

    color: gray;
}

#dark {
    background-color: rgba(11, 22, 33, 0.9);
    /*border: 1px solid rgba(230, 50, 50, 0.8);*/
    box-shadow: none;
}

#light {
    background-color: white;
    /*border: 1px solid rgba(55, 55, 55, 0.3);*/
    box-shadow: none;
}

.visible {
    display: block;
}

.invisible {
    display: none;
}