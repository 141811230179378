.wrapper {
    width: 280px;
    min-height: 260px;
    font-size: large;
    color: rgb(121,121,121);   
    margin: 15px;
    transition: all 0.5s ease;
    border-radius: 4px;
    padding: 8px;
    display: inline-block;
    text-align: left;
}

@media (max-width: 800px) {
    .wrapper {
        width: 98%;
    }
}

.wrapper:hover {
   /* transform: scale(1.02);*/
    cursor: pointer;
}

.imageWrapper {
    height: auto;
    display: inline-block;
}

#light {
    background-color: white;
    border-radius: 0.25rem;
    box-shadow: 10px 10px 10px rgb(0 0 0 / 50%);
}

#dark {
    background-color: rgba(10, 20, 30, 0.6); /*background-color: rgb(11, 22, 33);*/
    
    /*color: rgba(230, 50, 50, 0.6);*/
    
}

#dark:hover {
    /*border: 1px solid rgba(230, 50, 50, 0.8);*/
    /*box-shadow: 10px 10px 10px rgba(230, 50, 50, 0.8);*/
    /*background-color: rgba(230, 50, 50, 0.5);*/
    color: rgb(186,186,186);
}

.descriptionWrapper {
    display: inline-block;
    font-size: 0.8em;
    width: 100%;
}

#dark .free {
    
}

#dark .recorded {
    
    
}

#dark .busy {
    
}

#light .free {
    
}

#light .recorded {
   
    
}

#light .busy {
    

}

.free {
    background-color: green;
    color: white;
    border-radius: 15px;
    width: max-content;
    text-align: center;
    padding: 0 5px 0 5px;
    font-weight: 500;
}

.inProgress {
    background-color: orange;
    color: white;
    border-radius: 15px;
    width: max-content;
    text-align: center;
    padding: 0 5px 0 5px;
    font-weight: 500;
}

.declined {
    background-color: red;
    color: white;
    border-radius: 15px;
    width: max-content;
    text-align: center;
    padding: 0 5px 0 5px;
    font-weight: 500;
}

.done {
    background-color: blue;
    color: white;
    border-radius: 15px;
    width: max-content;
    text-align: center;
    padding: 0 5px 0 5px;
    font-weight: 500;
}
