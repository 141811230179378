.wrapper {
    display: block;
    width: 100%;
    text-align: left;
    font-size: 1.2em;
    padding: 1.1em;  
    border: 1px solid transparent;
}

@media (max-width: 800px) {
    .wrapper {
        width: 20%;
        display: inline-block;
    }
}

.wrapper#dark  {
    /*color: rgba(230, 50, 50, 0.7);*/
    color: rgba(72, 253, 248, 1);    
    color: rgba(72, 253, 248, 1);  
    color: aquamarine;
    color: rgb(11, 218, 255);
    color: rgba(255, 255, 255, 0.75);
}

.wrapper#light {
    color: rgba(0, 0, 0, 0.75);
}

.wrapper#dark:hover {
    cursor: pointer;
    /*border-bottom: 1px solid rgba(93, 182, 255, 1);
    background-color: rgba(183, 53, 55, 0.6);*/
    /*color: orange;*/
    /*color: #f9c3ff;
    background-color: rgba(253, 93, 255, .6);
    box-shadow: 0 0 30px 3px rgba(253, 93, 255, .6);*/
    /*color: rgba(255, 255, 255, 0.8);*/
    /*background-color: rgba(93, 182, 255, 0.4);*/
    /*background-color: rgba(32, 32, 32, 0.5);
    border: 1px solid rgb(252, 178, 224);
    border: 1px solid rgba(90,225,252,.99);
    color: rgb(0, 195, 255);
    box-shadow: 0px 0px 88px -4px rgba(233, 46, 161,0.9);*/
    color: rgb(90,225,252);
    /*border-right: 2px solid rgba(90,225,252,.99);*/
    /*background-color: rgba(90,225,252,.2);;*/
}

.wrapper#light:hover {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.55);
}

.iconWrapper {
    display: inline-block;
    min-width: 1.2em;
}



.titleWrapper {
    display: inline-block;
    margin-left: 10px;
}

@media (max-width: 600px) {
    .titleWrapper {
        display: none;
    }
}

@media (max-width: 600px) {
    .firstWrapper {
        display: none;
    }
}