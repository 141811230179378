.wrapper {
    width: 30px;
    height: 44px;
    margin: 2px;
    line-height: 40px;
    text-align: center;
    font-size: small;
    font-weight: 500;
    color: white;
    user-select: none;
}

.wrapper:hover {
   cursor: grab;

}

.wrapper:active{
    cursor:  grabbing;
    
 }

.choosed {

}

.free {
    background-color: rgb(123, 197, 13);
}

.free:hover {
    background-color: rgb(143, 228, 17);
}

.reserved {
    background-color: rgb(204, 186, 26);
}

.reserved:hover {
    background-color: rgb(238, 217, 27);
}

.busy {
    background-color: rgb(184, 48, 14);
}

.busy:hover {
    background-color: rgb(231, 62, 20);
}

.parkingTextBox {
    width: 100%;
    background-color: transparent;
    border: none;
    color: white;
    height: 50%;
    text-align: center;
}

.parkingTextBox:focus {
    outline:none;
}

.parkingTextBox[type='number'] {
    -moz-appearance:textfield;
}

.parkingTextBox::-webkit-outer-spin-button,
.parkingTextBox::-webkit-inner-spin-button {
    -webkit-appearance: none;
}