.navbarCustomClass {
    /*border-bottom: 1px solid rgba(230, 50, 50, 0.8);*/
}

.nav{
    color: rgba(84, 84, 84, 1) !important;
    color: rgb(51, 69, 230) !important;
}

.navIcon {
    text-transform: uppercase;
}

#dark .navIcon {
    color: rgba(72, 253, 248, 1) !important;
    color: aquamarine !important;
    color:rgb(11, 218, 255) !important;
    text-transform: uppercase;
}

#light .navIcon {
    color: rgba(0, 0, 0, 0.75) !important;
    color: rgba(255, 255, 255, 0.8) !important;
    text-transform: uppercase;
}

.navText {
    color:rgba(0, 0, 0, 0.75) !important;
    display: inline-block;
    margin-right: 10px;
    /*color: white!important;*/    
}

#dark .navText{
    color: rgba(72, 253, 248, 1) !important;
    color: aquamarine !important;
    color: rgb(11, 218, 255) !important;
    color:rgba(240, 240, 240, 0.75) !important;
}

#light .navText {
    color: rgba(0, 0, 0, 0.75) !important;
    color: rgba(255, 255, 255, 0.8) !important;
    display: inline-block;
    margin-right: 10px;
    /*color: white!important;*/
    
}



.navText #light {
    color: white !important;
}

.navText:hover {
    color: rgb(230, 50, 50) !important;
}

.rightContainer {
    color: white;
}

.customIcon {
    margin: 5px 10px;
    font-size: 1.6em;
    background-color: rgb(11, 22, 33);
}

#dark {
    /*background-color: rgb(10, 20, 30);*/
    background-color: rgba(10, 20, 30, 0.7);
}

#light {
    /*background-color: white;*/
    background-color: rgb(111, 172, 237);
    border-bottom: 1px solid rgba(180, 180, 180, 0.8);
}

.rightIcon {
    display: inline-block;
}

.rightCell {
    color:rgba(240, 240, 240, 0.75);
    display: inline-block;
    margin: 10px;
    width: auto;
}

.rightCell:hover {
    cursor: pointer;
    color: rgb(230, 50, 50) !important;
}