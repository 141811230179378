.wrapper {
    width: 100%;
    height: 500px;
    
    margin-top: 20px;
    margin-bottom: 20px;
}

#dark .wrapper   {
    background-color:  rgba(22, 22, 22, 0.3);
}

#light .wrapper  {
    background-color: red
}

.crudButtons {
    display: inline-block;
    margin: 4px;
}

.parkingAreaWrapper {
    position: relative;
    width: 100%;
    z-index: 1;
    /*background-color: rgba(252, 252, 252, 1);*/
}

.collapsed {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
}

.expand {
    position: fixed;
    top: 20px;
    right: 20px;
    width: 90%;
}

.shield {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}