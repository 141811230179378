.wrapper {
    text-align: left;
    width: 250px;
    height: 280px;
    font-size: small;
    color: rgb(121,121,121);   
    box-shadow: 10px 10px 10px rgb(0 0 0 / 50%);
    margin: 8px;
    transition: all 0.5s ease;
    padding: 8px;
    display: inline-block;
    border-radius: 4px;
    
    /*border-left: 8px solid orange;*/
}

.green {
    border-left: 4px solid greenyellow;
}

.orange {
    border-left: 4px solid orange;
}

.red {
    border-left: 4px solid orange;
}

.blue {
    border-left: 4px solid rgb(0, 89, 255);
}

.black {
    border-left: 4px solid rgb(2, 9, 20);
}


.descriptionWrapper {
    cursor: pointer;
}

.imageWrapper {
    text-align: center;
    width: 100%;
    text-align: center;
}

.free {
    background-color: green;
    color: white;
    border-radius: 15px;
    width: max-content;
    text-align: center;
    padding: 0 5px 0 5px;
    font-weight: 500;
}

.opened {
    background-color: orange;
    color: white;
    border-radius: 15px;
    width: max-content;
    text-align: center;
    padding: 0 5px 0 5px;
    font-weight: 500;
}

.inProgress {
    background-color: orange;
    color: white;
    border-radius: 15px;
    width: max-content;
    text-align: center;
    padding: 0 5px 0 5px;
    font-weight: 500;
}

.declined {
    background-color: red;
    color: white;
    border-radius: 15px;
    width: max-content;
    text-align: center;
    padding: 0 5px 0 5px;
    font-weight: 500;
}

.done {
    background-color: blue;
    color: white;
    border-radius: 15px;
    width: max-content;
    text-align: center;
    padding: 0 5px 0 5px;
    font-weight: 500;
}