.headerWrapper {
    width: 200;
    /*min-width: 600px;*/
    margin: 0 auto;
    padding: 4%;
}

.ticketWrapper {
    display: inline-block;
}

.formWrapper {
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    padding: 10px;
    box-shadow: 10px 10px 10px rgb(0 0 0 / 50%);
    transition: all 0.5s ease;
    color: rgb(121,121,121);
    background-color: white;

    height: 400px;
}

.footerWrapper {
    width: 100%;
    height: 20%;
    text-align: center;
}

#dark {
    background-color: rgb(11, 22, 33);
    border: 1px solid rgba(230, 50, 50, 0.8);
    box-shadow: none;
}