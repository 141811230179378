.serviceLinkWrapper {
    margin: 20px;
    width: 300px;
    height: 300px;  
    display: inline-block;
    padding: 10px;
    transition: all 0.4s ease;
    border-radius: 10px;
    /*color: rgb(51, 69, 230);*/  
}

.serviceLinkWrapper:hover {
    transform: scale(1.03);
    cursor: pointer;
    box-shadow: 0px 0px 7px rgba(207, 21, 114, 0.9);
}

.iconWrapper {
    width: 100%;
    height: 80%;
    font-size: 7em;
}

.nameWrapper {
    width: 100%;
    height: 20%;
}

#light {
    background-color: white;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    color: gray; 
    box-shadow: 10px 10px 10px rgb(0 0 0 / 50%);
}

#dark {
    background-color: rgba(11, 22, 33, 0.7);
    border: 1px solid rgba(230, 50, 50, 0.8);
    color: rgb(93, 182, 255);
    /*box-shadow: 7px 7px 7px rgba(207, 21, 114, 0.4);*/
}

.green {
    border: 1px solid greenyellow !important;
    color: greenyellow !important;
}

.orange {
    border: 1px solid orange !important;
    color: orange !important;
}

.red {
    border: 1px solid rgb(212, 78, 37) !important;
    color: rgb(212, 78, 37) !important;
}

.blue {
    border: 1px solid rgb(93, 182, 255) !important;
    color: rgb(93, 182, 255) !important;
}

.pink {
    border: 1px solid rgb(214, 25, 231) !important;
    color: rgb(214, 25, 231) !important;
}

.yellow {
    border: 1px solid rgb(220, 255, 93) !important;
    color: rgb(220, 255, 93) !important;
}

.black {
    border-left: 4px solid rgb(2, 9, 20);
    color: rgb(2, 9, 20);
}
