.wrapper {
    width: 300px;
    min-height: 400px;
    font-size: large;
    color: rgb(121,121,121);   
    margin: 10px;
    transition: all 0.5s ease;
    border-radius: 0px;
    display: inline-block;
    text-align: left;
    vertical-align: top;
}

@media (max-width: 800px) {
    .wrapper {
        width: 100%;
    }
  }

.wrapper:hover {
    transform: scale(1.02);
    cursor: pointer;
}

.imageWrapper {
    max-height: 200px;
    height: 200px;
    max-width: 100%;
    object-fit: cover;
}

@media (max-width: 800px) {
    .imageWrapper {
        max-height: 128px;
    }
  }

#light {
    background-color: white;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    box-shadow: 10px 10px 10px rgb(0 0 0 / 50%);
}

#dark {
    background-color: rgb(11, 22, 33);
        
}

#dark:hover {
    /*border: 1px solid rgba(230, 50, 50, 0.8);*/
    /*box-shadow: 10px 10px 10px rgba(230, 50, 50, 0.8);*/
    /*background-color: rgba(230, 50, 50, 0.4);
    color: rgb(186,186,186);*/
    border: 1px solid rgba(0, 195, 255, 0.5);
}

.descriptionWrapper {
    /*display: inline-block;*/
    float: left;
    padding: 8px;
}
