.cardsWrapper {
    text-align: center;
}

.formWrapper {
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    padding: 10px;
    box-shadow: 10px 10px 10px rgb(0 0 0 / 50%);
    transition: all 0.5s ease;
    color: rgb(121,121,121);
    background-color: white;

    height: 400px;
}


#dark {

    box-shadow: none;
}

h2 {
    color:rgba(240, 240, 240, 0.75);
}

#light h2 {
    color:rgba(240, 240, 240, 0.75);
}

#dark p {
    color:rgba(240, 240, 240, 0.75);
}

#light p {
    color:rgba(240, 240, 240, 0.75);
}