.formWrapper {
    transition: all 0.5s ease;
}


#dark {
    box-shadow: none;
}

.closeContainer {
    position: relative;
    height: 10px;
}

.btnClose {
    position: absolute;
    right: 0;
}