.userMenuWrapper {
    position: absolute;
    top: calc(3.8rem + 1px);
    right: 0;
    width: 300px;
    padding: 10px;
    color:rgba(240, 240, 240, 0.75);
    float: right;
    height: auto;
    animation: ani 0.5s forwards;
    z-index: 2;
}

@keyframes ani {
    0% {transform: translateX(+150%);}
    100% {transform: translateY(0);}
  }

#dark {
    background-color: rgba(11, 22, 33, 0.9);
    box-shadow: none;
}

#light {
    background-color: rgba(0, 122, 204, 0.9);
    box-shadow: none;
    color: white;
}

.title {
    text-align: left;
}

.title:hover {
    cursor: pointer;
}

.visible {
    display: block;
}

.invisible {
    display: none;
}