.formWrapper {
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    padding: 10px;
    box-shadow: 10px 10px 10px rgb(0 0 0 / 50%);
    transition: all 0.5s ease;
    color: rgb(121,121,121);
    background-color: white;
    margin-top: 20px;
}

#dark {
    background-color: rgba(11, 22, 33, 0.7);
    border: 1px solid rgba(230, 50, 50, 0.5);
    box-shadow: none;
}