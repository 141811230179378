.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background-color: rgba(11, 22, 33, 0.9);
    z-index: 10;
}

@media (max-width: 800px) {
    .wrapper {
        position: fixed !important;
        top: 88%;
        right: 20;
        height: 12%;
        width: 100% !important;
        /*/background-color: rgba(11, 22, 33, 0.9);*/
        z-index: 10;
        text-align: center;
    }
}

#dark {
    background-color: rgba(11, 22, 33, 0.8);
}

#light {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.25);
}

.collapsed {
    width: 70px;
    transition: width 0.2s;
}

.collapsed:hover {
    /*width: 240px;
    transition: width 0.2s;*/
}


.expanded {
    width: 240px;
    transition: width 0.2s;
}

.wrapper:hover {
    
}