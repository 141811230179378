.imageItem {
    max-height: 96vh;
    
}

.commonWrapper {
    position: relative;
}

.topWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.leftWrapper {
    position: absolute;
    top: 30%;
    left: 15%;
    width: 60%;
    height: auto;
    
    padding: 20px;
}

@media (max-width: 600px) {

    .leftWrapper {
        position: absolute;
        top: 50%;
        left: 0%;
        width: 100%;
        height: 50%;
        
        padding: 20px;
        text-align: center;
    }
}

.rightWrapper {
    position: absolute;
    top: 10%;
    right: 10%;
    width: 30%;
    height: auto;
    padding: 20px;
}

@media (max-width: 600px) {

    .rightWrapper {
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 50%;
        padding: 20px;
    }
}

.iconWrapper {
    font-size: 20em;
} 

@media (max-width: 600px) {

    .iconWrapper {
        font-size: 16em;
        text-align: center;
    }
}

#light .leftWrapper {
    /*background-color: rgba(222, 222, 222, 0.7);*/
    /*border-radius: 5px;*/
}

#dark .leftWrapper {
    /*color: aquamarine;
    text-shadow: 0 5px 50px aquamarine;*/
}

#light .leftWrapper {

}

.titleWrapper {
    font-size: 4vw;
    font-weight: 600;
    text-transform: uppercase;
}

@media (max-width: 600px) {

    .titleWrapper {
        font-size: 3em;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
    }
}

.descriptionWrapper {
    font-size: 2vw;
}

@media (max-width: 600px) {

    .descriptionWrapper {
        font-size: 4vw;
    }
}

.buttonWrapper {
    padding-top: 2%;
}

@media (max-width: 600px) {

    .buttonWrapper {
    }
}

#dark .imageItem {
    filter: brightness(15%);
}

#light .imageItem {
    filter: opacity(0.55) drop-shadow(0 0 0 rgb(3, 159, 249));
}

#dark .title {
    color: rgb(93, 182, 255);
}

#light .title {

}

#dark .description {
    color: rgb(93, 182, 255);
}

#light .description {
}

.imgWrapper {
    height: 100%;
    width: auto;
    background-color: red;
}

  
#dark .neon{
    font-size: 120px;
    color: #FFD5FF;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 400;
    letter-spacing: 8px;
    text-shadow: 1px 0px 4px #FFD5FF, 2px 0px 4px #FFD5FF, 3px 0px 4px #FFD5FF, 2px 0px 3px #D42CCA, 2px 3px 15px #D42CCA, 2px 0px 15px, 5px 0px 125px, 20px 0vw 200px #D42CCA,40px 0vw 200px #D42CCA;
  }
  
  #dark .flickerSlow{
    animation: flicker 3s linear infinite;
  }
  
  #dark .flickerFast{
    animation: flicker 1s linear infinite;
  }
  
  @keyframes flicker {
      0%, 19.999%, 22%, 62.999%, 64%, 64.999%, 70%, 100% {
      opacity: .99;
          
      }
      20%, 21.999%, 63%, 63.999%, 65%, 69.999% {
          opacity: 0.4;
      }
  }

  .colorWrapper {
    height: 90vh;
  }
  
  