.formWrapper {
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    box-shadow: 10px 10px 10px rgb(0 0 0 / 50%);
    transition: all 0.5s ease;
    color: rgb(121,121,121);
    background-color: white;
    padding: 5px;
    height: auto;
}



#dark {
    background-color: rgba(11, 22, 33, 0.9);
    /*border: 1px solid rgba(230, 50, 50, 0.8);*/
    box-shadow: none;
}

.imageWrapper {
    /*max-height: 200px;
    height: 100px;*/
    max-width: 100%;
    object-fit: cover;
}

.widthWrapper {
    display: block;
}

@media (max-width: 600px) {
    .widthWrapper {
        display: none;
    }
  }