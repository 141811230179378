.wrapper {
    display: flex;
}

.taskColumnStyles {
  margin: 8px;
  display: flex;
  width: 80%;
  min-height: 10vh;
}

.taskList {
    min-height: 100px;
    display: flex;
    flex-direction: column;
    min-width: 290px;
    border-radius: 5px;
    padding: 15px 15px;
    margin-right: 45px;
}

#dark .taskList {
    background: #111111;
} 

#light .taskList {
    background: rgba(230, 230, 230, 0.9);
} 

.title {
    
    padding: 2px 10px;
    border-radius: 5px;
    align-self: flex-start;
}

#dark .title {
    color: #10957d;
    background: black;
}

#light .title {
    color: gray;
    background: white; 
}