.authFormContainer {
    position: fixed;
    top: 30%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 500px;
    padding: 10px;
    color:rgba(240, 240, 240, 0.75);
}

.authForm {
    margin: 10px;
}

@media (max-width: 600px) {
    .authForm {
        position: fixed;
        transform: translate(0, 0);
        top: 2%;
        left: 1%;
        margin: 0 !important;
        width: 98%;
    }
}


#dark {
    background-color: rgba(11, 22, 33, 0.9);
    border: 1px solid rgba(0, 195, 255, 0.5);
    box-shadow: none;
}

#light {
    background-color: white;
    border: 1px solid gray;
    box-shadow: none;
}

.visible {
    display: block;
}

.invisible {
    display: none;
}

.btnClose:hover {
    cursor: pointer;
}