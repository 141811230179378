.formWrapper {
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    padding: 20px;
    box-shadow: 10px 10px 10px rgb(0 0 0 / 50%);
    transition: all 0.5s ease;
    /*color: rgb(51, 69, 230);*/
    
    
    margin-top: 20px;
    margin-bottom: 20px;

    border-radius: 4px; 
}

#light {
    background-color: white;
    color: gray;
}

#dark {
    background-color: rgba(10, 20, 30, 0.6);
    background-color: rgb(12, 22, 34);
    box-shadow: none;
    color: rgba(93, 182, 255, 0.6);
    color: rgba(255, 255, 255, 0.3);
}

.filterWrapper {
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    padding: 20px;
    box-shadow: 10px 10px 10px rgb(0 0 0 / 50%);
    transition: all 0.5s ease;
    /*color: rgb(51, 69, 230);*/
    color:rgba(240, 240, 240, 0.75);
    
    margin-top: 20px;
    margin-bottom: 20px;
    min-height: 10vh;
    border-radius: 4px; 
}

.green {
    border-left: 4px solid greenyellow;
    color: greenyellow;
}

.orange {
    border-left: 4px solid orange;
    color: orange;
}

.red {
    border-left: 4px solid rgb(255, 60, 0);
    color: rgb(255, 60, 0);
}

.blue {
    border-left: 4px solid rgba(93, 182, 255, 0.6);
    color: rgba(93, 182, 255, 0.6);
}

.black {
    border-left: 4px solid rgb(2, 9, 20);
    color: rgb(2, 9, 20);
}