.wrapper {
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    padding: 10px;
    box-shadow: 10px 10px 10px rgb(0 0 0 / 50%);
    transition: all 0.5s ease;
    color: rgb(121,121,121);
    background-color: white;
    margin-top: 20px;
    height: auto;
    
}