.wrapper {
    width: 26px;
    height: 40px;
    margin: 2px;
    text-align: center;
    font-size: small;
    font-weight: 500;
    color: white;
    border-left: 1px solid white;
    border-right: 1px solid white;
}

.wrapper:hover {
   cursor: pointer;
}

.choosed {

}

.free {
    background-color: rgb(123, 197, 13);
}

.free:hover {
    background-color: rgb(143, 228, 17);
}

.reserved {
    background-color: rgb(204, 186, 26);
}

.reserved:hover {
    background-color: rgb(238, 217, 27);
}

.busy {
    background-color: rgb(184, 48, 14);
}

.busy:hover {
    background-color: rgb(231, 62, 20);
}