.backgroundWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 20;
}

#dark .backgroundWrapper {
    background-color: rgba(22, 22, 22, 0.8);
}

#light .backgroundWrapper {
}



.labelWrapper {
    position: relative;

}

#light .labelWrapper {
    color: rgba(255, 255, 255, 0.6) !important; 
}