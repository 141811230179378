.wrapper {
    width: 200px;
    width: 300px;
    font-size: large;
    color: rgb(121,121,121);   
    box-shadow: 10px 10px 10px rgb(0 0 0 / 50%);
    margin: 4px;
    transition: all 0.5s ease;
    border-radius: 4px;
    padding: 8px;
    display: inline-block;
    text-align: center;
    display: inline-block;
}

.descriptionWrapper {
    cursor: pointer;
}

.imageWrapper {
    text-align: center;
    width: 100%;
    text-align: center;
}

h2 {
    color: rgb(121,121,121);  
    font-size: 2.2em;
}

.free {
    background-color: green;
    color: white;
    border-radius: 15px;
    width: max-content;
    text-align: center;
    padding: 0 5px 0 5px;
    font-weight: 500;
}

.opened {
    background-color: orange;
    color: white;
    border-radius: 15px;
    width: max-content;
    text-align: center;
    padding: 0 5px 0 5px;
    font-weight: 500;
}

.inProgress {
    background-color: orange;
    color: white;
    border-radius: 15px;
    width: max-content;
    text-align: center;
    padding: 0 5px 0 5px;
    font-weight: 500;
}

.declined {
    background-color: red;
    color: white;
    border-radius: 15px;
    width: max-content;
    text-align: center;
    padding: 0 5px 0 5px;
    font-weight: 500;
}

.done {
    background-color: blue;
    color: white;
    border-radius: 15px;
    width: max-content;
    text-align: center;
    padding: 0 5px 0 5px;
    font-weight: 500;
}