.linkWrapper {
    text-decoration: none;
    text-align: center;
}

.wrapper {
    width: 30%;
    text-align: center;
    background-color: transparent;
    padding: 8px;
    font-size: 1.6vw;
}

#dark.wrapper {
    border: 2px solid rgb(0, 195, 255);
    /*box-shadow: 0 0 30px 3px rgba(253, 93, 255, .6);*/
}

#light.wrapper {
    /*border: 2px solid rgb(3, 159, 249);
    background-color: rgb(3, 159, 249);*/
    border: 3px solid rgb(6, 14, 22);
    color: rgb(6, 14, 22) !important;
}

.wrapper:hover {
    cursor: pointer;
}



#dark.wrapper:hover {
    /*box-shadow: 0px 0px 88px -4px rgba(233, 46, 161,0.9);
    border: 1px solid rgb(252, 178, 224);*/
}

#light.wrapper:hover {
    
}