.wrapper {
    position: absolute;
    top: -12px;
    right: -12px;
    color: white;
    background-color: red;
    width: 1.6em;
    height: 1.6em;
    border-radius: 50%;
    text-align: center;
    font-size: 0.9em;
}