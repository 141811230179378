.wrapper {
    position: relative;
    width: 100%;
    height: 600px;
    
    margin-top: 20px;
    margin-bottom: 20px;
}

#dark .wrapper   {
    background-color:  rgba(22, 22, 22, 0.3);
}

#light .wrapper  {
    background-color: white
}