.wrapper {
    width: 100%;
    min-height: 70vh;
    margin-top: 20px;
    margin-bottom: 20px;
}

.slotWrapper {
    display: inline-block;
}

.slotListWrapper {
   text-align: center;
}

.formsWrapper {
    display: inline-block;
}