.wrapper {
    width: 220px;
    height: 180px;
    font-size: large;
    color: rgb(121,121,121);   
    margin: 8px;
    transition: all 0.5s ease;
    border-radius: 4px;
    padding: 8px;
}

.wrapper:hover {
    transform: scale(1.02);
    cursor: pointer;
}

.imageWrapper {
    width: 200px;
    height: 100px;
    display: inline-block;
}

#light {
    background-color: white;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    box-shadow: 10px 10px 10px rgb(0 0 0 / 50%);
}

#dark {
    background-color: rgb(11, 22, 33);
    border-width: 1px 1px 1px 5px;
    border-style: solid;
    border-color: rgba(230, 50, 50, 0.8);
    /*color: rgba(230, 50, 50, 0.6);*/
    
}

#dark:hover {
    /*border: 1px solid rgba(230, 50, 50, 0.8);*/
    /*box-shadow: 10px 10px 10px rgba(230, 50, 50, 0.8);*/
    background-color: rgba(230, 50, 50, 0.5);
    color: rgb(186,186,186);
}

.descriptionWrapper {
    display: inline-block;
}
