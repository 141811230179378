.formWrapper {
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    padding: 10px;
    box-shadow: 10px 10px 10px rgb(0 0 0 / 50%);
    transition: all 0.5s ease;
    color: rgb(121,121,121);
    background-color: white;

    height: 400px;
}

.headerWrapper {
    width: 100%;
    height: 20%;
}

.bodyWrapper {
    width: 100%;
    height: 60%;
}

.footerWrapper {
    width: 100%;
    height: 20%;
    text-align: center;
}

.chooseButtons {
    margin: 4px;
    font-size: 1em;
    border: 1px solid rgba(0,0,0,.125);
    background-color: white;
    color: rgb(121,121,121);
}

.serviceLinkWrapper:hover {
    background-color: white;
    color: rgb(121,121,121);
    display: inline-block;
}

h2 {
    display: inline-block;
}

#dark {
    background-color: rgb(11, 22, 33);
    border: 1px solid rgba(230, 50, 50, 0.8);
    box-shadow: none;
}
