.userMenuWrapper {
    
}

.userMenuWrapper:hover {
    cursor: pointer;
}

.iconWrapper {
    display: inline-block;
    margin: 5px;
}

.titleWrapper {
    display: inline-block;
    margin: 5px;
}

#dark:hover {
    /*border: 1px solid rgba(230, 50, 50, 0.8);*/
    /*box-shadow: 10px 10px 10px rgba(230, 50, 50, 0.8);*/
    /*background-color: rgba(230, 50, 50, 0.4);
    color: rgb(186,186,186);*/
    cursor: pointer;
    /*border-bottom: 1px solid rgba(93, 182, 255, 1);
    background-color: rgba(183, 53, 55, 0.6);*/
    /*color: orange;*/
    color: #f9c3ff;
    background-color: rgba(253, 93, 255, .6);
    box-shadow: 0 0 30px 3px rgba(253, 93, 255, .6);
}